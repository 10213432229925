import React, { FC, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';
import { rem } from 'polished';
import { Button } from '@components/Atoms';
import useBasketContext from '@hooks/useBasketContext';
import { ButtonType } from '@components/Atoms/Button/types';
import StyledCardPackageSpeed from '../ChoosePackage/styled/StyledCardPackageSpeed';
import Image from 'next/image';
import ArrowCircleUpImage from '@public/arrow-circle-up.png';
import ArrowCircleDownImage from '@public/arrow-circle-down.png';
import Link from 'next/link';
import { ImageDataT, ProductT } from '@lib/types';
import usePromotionsContext from '@hooks/usePromotionsContext';
import { getMonthlyPriceCalculated, getProductPromotion } from '@lib/utils';
import usePostcodeContext from '@hooks/usePostcodeContext';

interface BundleComponentI {
  display: boolean;
  title: string;
  bundleDescription?: string;
  primaryBundleProductId: string;
  image?: ImageDataT;
  internalLink?: string;
  bundleVariant2024Sept: string;
  bundleProducts: Array<ProductT>;
  primaryBundleProduct: ProductT;
}

const STYLED = {
  wrapper: styled.section``,
  content: styled.div`
    background-color: var(--color-blue);
    box-shadow: rgba(110, 133, 191, 0.5) 0px 14px 24px;
    border-radius: 20px;
    padding: ${rem(30)};
    padding-top: 7rem;
    margin-top: 10rem;
    position: relative;

    .bundle-image {
      position: absolute;
      top: -10rem;
      left: 50%;
      transform: translateX(-50%);
      width: 250px;
      height: 250px;
    }

    @media ${breakpoints.tablet} {
      margin-top: 0;
      display: flex;
      padding-top: ${rem(30)};
      padding-left: 8rem;
      max-width: 600px;
      margin: 0 auto;

      .bundle-image {
        left: -7rem;
        transform: translateX(0);
        top: 50%;
        transform: translateY(-50%);
      }
    }

    @media ${breakpoints.desktop} {
      padding: ${rem(50)} ${rem(50)} ${rem(105)};
      padding-left: 13rem;
      width: 100%;
      max-width: 900px;
      margin: 0 auto;
      margin-right: 0;

      .bundle-image {
        left: -12rem;
        width: 400px;
        height: 400px;
      }

      .bundle-content {
        display: flex;
        gap: 4rem;
        justify-content: space-between;
        width: 100%;

        & > div:first-of-type {
          max-width: 65%;
        }
      }
    }

    @media ${breakpoints.desktopLarge} {
      max-width: ${rem(1073)};
      margin: 0 auto;

      .bundle-content {
        & > div:first-of-type {
          max-width: 60%;
        }
      }
    }
  `,
  title: styled.h2`
    font-size: ${rem(20)};
    color: var(--color-black);
    font-weight: 700;
    margin-bottom: 0;
    line-height: 1;
    margin-bottom: 0.5rem;

    @media ${breakpoints.tablet} {
      font-size: ${rem(22)};
    }
  `,
  info: styled.div`
    display: flex;
    flex-wrap: wrap;
    column-gap: 2rem;

    .speed {
      padding: unset;
      font-family: var(--font-geomanist);
    }
  `,
  description: styled.p`
    font-size: ${rem(30)} !important;
    color: var(--color-primary) !important;
    font-weight: bold;
    font-family: var(--font-geomanist);
    line-height: 1.1;

    @media ${breakpoints.tablet} {
      font-size: ${rem(35)} !important;
    }
  `,
  price: styled.p`
    font-family: var(--font-geomanist);
    font-weight: 300;
    font-size: ${rem(15)};
    line-height: 1.1;

    span {
      font-size: ${rem(52)};
      color: var(--color-black);
      font-weight: 700;
    }

    @media ${breakpoints.desktop} {
      margin-bottom: 0 !important;
      position: relative;
      bottom: -1.9rem;
    }
  `,
  footer: styled.div`
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    @media ${breakpoints.desktop} {
      gap: 2rem;
    }
  `,
};

export const BundleComponent: FC<BundleComponentI> = ({
  display,
  title,
  bundleDescription,
  image,
  internalLink,
  bundleVariant2024Sept,
  bundleProducts,
  primaryBundleProduct,
}) => {
  const { clearBasket, bundle, setBundleProducts } = useBasketContext();
  const { promotions } = usePromotionsContext();
  const { postcodeItem } = usePostcodeContext();

  // Promo (if applicable)
  const promo = useMemo(() => {
    return getProductPromotion({
      id_product: primaryBundleProduct.id_product,
      coverage: postcodeItem?.coverage,
      promotions,
    });
  }, [primaryBundleProduct.id_product, postcodeItem?.coverage, promotions]);

  const deductablePromo = Array.isArray(promo)
    ? promo.find(({ promotion_type }) => promotion_type === 'RECURAMTOFF')
    : promo;

  // Primary product monthly price
  const primaryProductMonthlyPrice = Number(
    getMonthlyPriceCalculated({
      monthly_price: primaryBundleProduct.monthly_price,
      exclude_vat_price: primaryBundleProduct.exclude_vat_price,
      addressType: postcodeItem?.address_type,
      promo: deductablePromo,
    }),
  );

  // Additional products monthly price
  const additionalProductsMonthlyPrice = bundleProducts
    .filter((product) => product.id_product !== primaryBundleProduct.id_product)
    .reduce((acc, product) => {
      return acc + Number(product.monthly_price);
    }, 0);

  const totalMontlyPrice = primaryProductMonthlyPrice + additionalProductsMonthlyPrice;

  if (!display) return null;

  return (
    <>
      <STYLED.wrapper id="bundle-select" className="slice">
        <div className="slice__inner">
          <STYLED.content>
            {image && image?.url && (
              <div className="bundle-image">
                <Image src={image.url} layout="fill" alt="Benny with gigafast jetpack" />
              </div>
            )}
            <div className="bundle-content">
              <div>
                <STYLED.title
                  dangerouslySetInnerHTML={{
                    __html: title,
                  }}
                />
                {bundleDescription && <STYLED.description>{bundleDescription}</STYLED.description>}
                <STYLED.info>
                  {totalMontlyPrice && (
                    <STYLED.price>
                      <span>£{totalMontlyPrice.toFixed(2)}</span>/month
                    </STYLED.price>
                  )}
                  <StyledCardPackageSpeed
                    className="speed"
                    isSymmetrical={
                      !!primaryBundleProduct.speed_down &&
                      !!primaryBundleProduct.speed_up &&
                      primaryBundleProduct.speed_down === primaryBundleProduct.speed_up
                    }
                  >
                    {(!primaryBundleProduct.speed_down ||
                      primaryBundleProduct.speed_down !== primaryBundleProduct.speed_up) && (
                      <div className="speed-container">
                        <Image
                          src={ArrowCircleDownImage}
                          width={17}
                          height={17}
                          alt="Download"
                          title="Download"
                        />
                        <div>
                          <span>{primaryBundleProduct.speed_down}</span> <span>Mbps*</span>
                        </div>
                        <div>Average download</div>
                      </div>
                    )}
                    {(!primaryBundleProduct.speed_up ||
                      primaryBundleProduct.speed_down !== primaryBundleProduct.speed_up) && (
                      <div className="speed-container">
                        <Image
                          src={ArrowCircleUpImage}
                          width={17}
                          height={17}
                          alt="Upload"
                          title="Upload"
                        />
                        <div>
                          <span>{primaryBundleProduct.speed_up}</span> <span>Mbps*</span>
                        </div>
                        <div>Average upload</div>
                      </div>
                    )}
                    {!!primaryBundleProduct.speed_down &&
                      !!primaryBundleProduct.speed_up &&
                      primaryBundleProduct.speed_down === primaryBundleProduct.speed_up && (
                        <>
                          <div className="speed-container">
                            <div>
                              <Image
                                src={ArrowCircleDownImage}
                                width={17}
                                height={17}
                                alt="Download"
                                title="Download"
                              />
                              <Image
                                src={ArrowCircleUpImage}
                                width={17}
                                height={17}
                                alt="Upload"
                                title="Upload"
                              />
                            </div>
                            <div>
                              <span>{primaryBundleProduct.speed_down}</span> <span>Mbps*</span>
                            </div>
                            <div>Average download/upload</div>
                          </div>
                        </>
                      )}
                  </StyledCardPackageSpeed>
                </STYLED.info>
              </div>
              <STYLED.footer>
                {bundleVariant2024Sept && bundleVariant2024Sept !== 'b' ? (
                  <Button
                    buttonType={ButtonType.PRIMARY}
                    onClick={bundle ? clearBasket : () => setBundleProducts(bundleProducts)}
                  >
                    {bundle ? 'Remove bundle' : 'Select bundle'}
                  </Button>
                ) : (
                  <Link href="#bundle-form" passHref>
                    <Button buttonType={ButtonType.PRIMARY}>
                      <a href="#bundle-form">Select bundle</a>
                    </Button>
                  </Link>
                )}
                <Link href={`#${internalLink ? internalLink : 'whats-included'}`} passHref>
                  <Button buttonType={ButtonType.HYPERLINK_SECONDARY}>
                    <a href={`#${internalLink ? internalLink : 'whats-included'}`}>Read more</a>
                  </Button>
                </Link>
              </STYLED.footer>
            </div>
          </STYLED.content>
        </div>
      </STYLED.wrapper>
    </>
  );
};
