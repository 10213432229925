interface ConditionalExecution {
  (condition: boolean, trueCallback: () => any, falseCallback: () => any): any;
}

export const handleConditionalExecution: ConditionalExecution = (
  condition,
  trueCallback,
  falseCallback,
) => {  
  if (condition) {
    return trueCallback();
  } else {
    return falseCallback();
  }
};
