import React, { FC, Key } from 'react';
import { StepsInfoT } from '@factories/RowFactory/components/StepsInfo/types';
import { Button } from '@components/Atoms';
import { ButtonType } from '@components/Atoms/Button/types';
import { renderComponents } from '@lib/utils';
import { InfoStep } from '@components/Molecules/InfoStep/InfoStep';
import Link from 'next/link';
import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

import SpeechBubble from '@public/Benny_speach_bubble.png';
import PeekingBenny from '@public/Benny_peeking_over.png';
import Image from 'next/image';
import { rem } from 'polished';
import useBasketContext from '@hooks/useBasketContext';
import { useRouter } from 'next/router';

const STYLED = {
  heading: styled.h2<{ sTheme: 'default' | 'withIcons' }>`
  color: var(--color-primary);
  line-height: 1;
  font-weight: 600;
  font-size: 1.75rem;
  margin-bottom: 3rem;

  ${({ sTheme }) =>
    sTheme === 'withIcons' &&
    `
    color: black;
    padding: 0 10vw;
    text-align: center;

    @media ${breakpoints.desktop} {
      font-size: 2.5rem;
      font-weight: 500;
    }
  `}}
  `,
  wrapper: styled.section<{ sTheme: 'default' | 'withIcons' }>`
  position: relative;
  ${({ sTheme }) =>
    sTheme === 'withIcons' &&
    `
    padding: 4rem 0;
    background-image: linear-gradient(to left, rgb(244 17 27 / 10%) 0%, rgb(4 2 253 / 20%) 200%);
    @media ${breakpoints.desktop} {

      padding: 6rem 0;
    }
  `}}
  `,

  peepingBenny: styled.div`
    position: relative;
    height: ${rem(150)};
    width: 100%;

    .peeking-benny {
      &__wrapper {
      }

      &__speech-bubble {
        position: absolute;
        width: 117px;
        height: 107px;
        bottom: 35px;
        right: 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media ${breakpoints.smallDesktop} {
          width: 137px;
          height: 125px;
        }

        &__text {
          position: absolute;
          font-size: ${rem(13)};
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 0.5rem;
          line-height: 1.2;
          color: var(--color-grey);

          span {
            font-size: ${rem(16)};
            font-weight: bold;
            text-align: center;
            color: var(--color-black);
          }

          a {
            text-decoration: underline;
          }
        }
      }

      &__benny {
        position: absolute;
        bottom: -28px;
        right: 65px;
        width: 234px;
        height: 109px;

        @media ${breakpoints.smallDesktop} {
          width: 274px;
          height: 127px;
          right: 80px;
          bottom: -32px;
        }
      }
    }
  `,
};

const PeekingBennyImage = () => (
  <STYLED.peepingBenny className="peeking-benny">
    <div className="peeking-benny__wrapper">
      <div className="peeking-benny__speech-bubble">
        <Image
          src={SpeechBubble}
          alt=""
          className="peeking-benny__image"
          layout="fill"
          objectFit="contain"
        />
        <p className="peeking-benny__speech-bubble__text">
          <span>
            Still in a contract?<br></br>
          </span>
          No worries. Call <a href="tel:+01337944027">01337944027</a>
        </p>
      </div>
      <div className="peeking-benny__benny">
        <Image
          src={PeekingBenny}
          alt=""
          className="peeking-benny__image"
          layout="fill"
          objectFit="contain"
        />
      </div>
    </div>
  </STYLED.peepingBenny>
);

export const StepsInfo: FC<StepsInfoT> = ({
  display,
  title,
  buttonText,
  infoSteps,
  theme = 'default',
  peepingBennyImage,
  bundleVariant2024Sept,
  bundleProducts,
}) => {
  const { clearBasket, bundle, setBundleProducts } = useBasketContext();
  const router = useRouter();
  const { pathname } = router;
  const isBundlesPage = pathname.includes('bundles');

  if (!infoSteps.length || !display) return null;

  const infoStepsComponent = infoSteps?.map(
    ({ label, display, instructions, icon = null }, key: Key) => {
      return (
        display && (
          <InfoStep
            number={parseInt(key.toString()) + 1}
            label={label}
            instructions={instructions}
            icon={icon}
          />
        )
      );
    },
  );

  return (
    <STYLED.wrapper className="pb-0 slice" sTheme={theme}>
      <div className="flex-col slice__inner">
        <STYLED.heading sTheme={theme}>{title}</STYLED.heading>
        <div className="flex flex-col flex-wrap items-start justify-center infoStep md:flex-row">
          {renderComponents({ components: infoStepsComponent })}
        </div>
        {!!buttonText && isBundlesPage && (
          <div className="flex justify-center">
            {bundleVariant2024Sept === 'a' ? (
              <Button
                buttonType={ButtonType.PRIMARY}
                onClick={bundle ? clearBasket : () => setBundleProducts(bundleProducts)}
              >
                {bundle ? 'Remove bundle' : 'Select bundle'}
              </Button>
            ) : (
              <Link passHref href="#bundle-form">
                <Button buttonType={ButtonType.PRIMARY} as="a">
                  {buttonText}
                </Button>
              </Link>
            )}
          </div>
        )}
        {!!peepingBennyImage && <PeekingBennyImage />}
      </div>
    </STYLED.wrapper>
  );
};
