/* eslint-disable jsx-a11y/no-autofocus */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Router, { useRouter } from 'next/router';

import { productController } from '@controllers/index';
import useBasketContext from '@hooks/useBasketContext';
import usePostcodeContext from '@hooks/usePostcodeContext';

import { UprnItemT, UprnStatus } from '@lib/types';

import { logger } from '@lib/logger';

import { Spinner } from '@components/Atoms/Spinner/Spinner';

import StyledPostcode from './styled/StyledPostcode';
import StyledPostcodeDropdown from './styled/StyledPostcodeDropdown';
import StyledPostcodeHeading from './styled/StyledPostcodeHeading';
import StyledPostcodeFeatureList from './styled/StyledPostcodeFeatureList';

import CheckIcon from '@public/icons/Check-Blue.svg';
import { setUprnCheckedServer } from '@controllers/ProductController/ProductController';
import { PostcodeSearch } from '@components/Molecules/PostcodeSearch';
import StyledPostcodeContainer from './styled/StyledPostcodeContainer';
import StyledPostcodeScroll from './styled/StyledPostcodeScroll';
import useCheckoutContext from '@hooks/useCheckoutContext';
import { handleConditionalExecution } from '@lib/utils/conditionalExecution';

export const SERVICE_AVAILABLE = [
  UprnStatus.PASSSERV,
  UprnStatus.CUSTOMER,
  UprnStatus.PREINSTALLED,
];
export const SERVICE_UNAVAILABLE = [UprnStatus.NOTPASSED, UprnStatus.STOPED];
export const SERVICE_PREORDER = [
  UprnStatus.DESIGN,
  UprnStatus.INPROGRESS,
  UprnStatus.PASSED,
  UprnStatus.CERTIFIED,
];

const redirect = ({
  addressType,
  clearBasket,
  coverage,
  postcode,
  registerInterestData,
  product,
  bundle,
}) => {
  if (coverage === UprnStatus.CUSTOMER) {
    clearBasket();
    return Router.push('/error/existing-customer');
  }

  const urlProduct = product ? `&product=${product}` : '';
  const queryString = `addressType=${addressType}`;

  if (SERVICE_UNAVAILABLE.includes(coverage) || SERVICE_PREORDER.includes(coverage)) {
    clearBasket();
    return Router.push('/service-unavailable');
  }

  if (registerInterestData) {
    const res = registerInterestData.find(({ postcodes }) => postcodes.includes(postcode));

    if (res) {
      return Router.push(`/coverage/location/${res.slug}?${queryString}#register-interest`);
    }
  }

  if (SERVICE_AVAILABLE.includes(coverage)) {
    // if (voucher) {
    // return Router.push(voucherRoute);
    // }
    // return Router.push(`/service-property-confirmation?${queryString}${urlProduct}`);
    if (bundle) {
      const trueCallback = () => Router.push(`/checkout`);
      const falseCallback = () => Router.push(`/ots?${queryString}${urlProduct}`);

      return handleConditionalExecution(
        process.env.NEXT_PUBLIC_DISABLE_OTS === 'true',
        trueCallback,
        falseCallback,
      );
    }

    // Disable OTS check
    const trueCallback = () =>
      Router.push(`/service-property-confirmation?${queryString}${urlProduct}`);
    const falseCallback = () => Router.push(`/ots?${queryString}${urlProduct}`);

    return handleConditionalExecution(
      process.env.NEXT_PUBLIC_DISABLE_OTS === 'true',
      trueCallback,
      falseCallback,
    );
  }

  if (SERVICE_PREORDER.includes(coverage)) {
    // if (voucher) {
    // return Router.push(voucherRoute);
    // }
    return Router.push(`/service-property-confirmation?${queryString}${urlProduct}`);
  }

  return 0;
};

interface PropsI {
  addressType?: string;
  clearBasket?: () => void;
  onSubmit: (data: any) => void;
  registerInterestData?: any;
  onSelectRow?: (data: any) => void;
  showTitle?: boolean;
  inline?: boolean;
  placeholder?: string;
  shouldRedirect?: boolean;
  focusOnLoad?: boolean;
  bundle?: boolean;
}

export const Postcode = ({
  addressType = 'RES',
  clearBasket,
  onSubmit,
  registerInterestData,
  onSelectRow,
  showTitle = true,
  inline = false,
  placeholder = '',
  shouldRedirect = true,
  focusOnLoad = true,
  bundle,
}: PropsI) => {
  const { postcodeString } = usePostcodeContext();
  const [currentPostcode, setCurrentPostcode] = useState<string>(postcodeString || '');
  const [uprns, setUprns] = useState<UprnItemT[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitted, setSubmitted] = useState<boolean>(!!postcodeString);
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false);
  const [hoveredRow, setHoveredRow] = useState<number>(-1);
  const throttleTimeout = useRef<any>(null);
  const router = useRouter();
  const product = router?.query?.product?.toString();
  const { setCheckoutField } = useCheckoutContext();

  const getUprnsByPostcode = useCallback(async () => {
    setHoveredRow(-1);
    setUprns(null);
    setIsLoading(true);

    clearTimeout(throttleTimeout.current);

    throttleTimeout.current = setTimeout(async () => {
      try {
        const postcodeCheck = await productController.checkPostcode({ postcode: currentPostcode });
        const uprnList = await productController.getUprnListServer({ postcode: currentPostcode });

        if (uprnList?.length) {
          setUprns(uprnList);
        } else if (postcodeCheck) {
          setUprns([
            {
              uprn: '',
              address: `${postcodeCheck.admin_ward}, ${postcodeCheck.admin_district}, ${postcodeCheck.postcode}`,
              coverage: UprnStatus.NOTPASSED,
              address_type: '',
              postcode: '',
              ismdu: false,
            },
          ]);
        }
      } catch (error) {
        setUprns(null);
      }

      setIsLoading(false);
      clearTimeout(throttleTimeout.current);
    }, 300);
  }, [currentPostcode]);

  // When the postcode input is changed, trigger the postcode lookup service.
  useEffect(() => {
    if (!isRedirecting && postcodeString) {
      getUprnsByPostcode();
    }
  }, [postcodeString, isRedirecting]);

  const submitRow = useCallback(
    async (row) => {
      setCurrentPostcode(row.address);
      // Reset voucher state
      setCheckoutField('voucher', false);
      setIsLoading(true);
      setIsRedirecting(true);

      let uprnData: any;

      try {
        uprnData = await productController.getUprnDataServer({ uprn: row.uprn });
        await setUprnCheckedServer(row.uprn);
      } catch (error) {
        logger.warn(error);
      }

      setTimeout(() => {
        onSubmit(uprnData || row);

        if (onSelectRow) {
          onSelectRow(uprnData || row);
        } else {
          if (shouldRedirect) {
            redirect({
              addressType,
              clearBasket,
              coverage: row.coverage,
              postcode: row.postcode,
              registerInterestData,
              product: product,
              bundle,
            });
          }
        }
      }, 1000);
    },
    [onSubmit, addressType, clearBasket, registerInterestData, product],
  );

  return (
    <StyledPostcode inline={inline}>
      {showTitle ? (
        <StyledPostcodeHeading>{`${
          isSubmitted && uprns && uprns.length ? 'Choose your property' : 'Enter your postcode'
        }`}</StyledPostcodeHeading>
      ) : null}
      <div className="max-w-[32rem] w-full">
        <StyledPostcodeContainer showShadow={isSubmitted}>
          <div
            className={
              inline
                ? `relative z-1 rounded-xl ${isSubmitted ? 'bg-white' : ''} `
                : 'relative z-1 bg-white rounded-xl min-w-full sm:min-w-[32rem]'
            }
          >
            {isSubmitted ? (
              <div className="flex justify-between items-center py-6 border-b-[1px] border-[rgba(#707070, 0.2)] mb-4 px-8">
                <p className="font-bold text-black uppercase">{currentPostcode}</p>
                <button
                  className="underline text-primary font-geomanist"
                  onClick={() => setSubmitted(false)}
                >
                  Change postcode
                </button>
              </div>
            ) : (
              <form
                className={inline ? '' : 'min-w-full sm:min-w-[25rem]'}
                onSubmit={(e) => {
                  e.preventDefault();
                  setSubmitted(true);
                  getUprnsByPostcode();
                }}
              >
                <PostcodeSearch
                  rootElement="div"
                  disabled={isRedirecting}
                  value={currentPostcode}
                  placeholder={placeholder}
                  isStandalone={true}
                  onChange={(event) => {
                    setCurrentPostcode(event?.target?.value?.toUpperCase() || '');
                  }}
                  className={inline ? '' : 'w-full max-w-[32rem] mx-auto'}
                  onKeyEnter={() => {
                    // Direct hit
                    if (uprns && uprns.length === 1) {
                      submitRow(uprns[0]);
                    }
                    // Direct hit
                    if (uprns && hoveredRow > -1) {
                      submitRow(uprns[hoveredRow]);
                    }
                    // @todo Add key up/down, select row, hit enter, etc.
                  }}
                  onKeyArrowUp={(event) => {
                    event.preventDefault();
                    if (uprns) {
                      setHoveredRow((prev) => {
                        if (prev === -1) {
                          return uprns.length - 1;
                        }
                        return prev - 1;
                      });
                    }
                  }}
                  onKeyArrowDown={(event) => {
                    event.preventDefault();
                    if (uprns) {
                      setHoveredRow((prev) => {
                        if (prev === uprns.length - 1) {
                          return -1;
                        }
                        return prev + 1;
                      });
                    }
                  }}
                  autoComplete="off"
                  autoFocus={focusOnLoad}
                />
              </form>
            )}
            {isSubmitted && (
              <PostcodeDropdown
                uprns={uprns}
                isLoading={isLoading}
                onSelect={submitRow}
                isSubmitted={isSubmitted}
              />
            )}
          </div>
        </StyledPostcodeContainer>
      </div>
    </StyledPostcode>
  );
};

export const PostcodeDropdown = ({ uprns, isLoading, onSelect, isSubmitted }) => {
  if (!isSubmitted) {
    return null;
  }
  if (isLoading) {
    return (
      <StyledPostcodeDropdown>
        <Spinner />
      </StyledPostcodeDropdown>
    );
  }
  if (!uprns || uprns.length < 1) {
    return (
      <StyledPostcodeDropdown>
        <strong>No results found.</strong>
        <div>Please try a different UK postcode.</div>
      </StyledPostcodeDropdown>
    );
  }
  return (
    <StyledPostcodeDropdown>
      <p className="font-bold text-black text-[.8rem] font-geomanist mb-2">Select your address</p>
      <StyledPostcodeScroll>
        {uprns?.map((row) => (
          <li key={row.uprn}>
            <button onClick={() => onSelect(row)}>{row.address}</button>
          </li>
        ))}
      </StyledPostcodeScroll>
    </StyledPostcodeDropdown>
  );
};

export const PostcodePopup = ({
  onSelectRow,
  showTitle = true,
  inline = false,
  placeholder,
}: {
  onSelectRow?: () => void;
  showTitle?: boolean;
  inline?: boolean;
  placeholder?: string;
}) => {
  const {
    featureList,
    registerInterestData,
    setPostcodeItem,
    addressType,
    setHasConfirmedAddress,
  } = usePostcodeContext();

  const { clearBasket } = useBasketContext();
  const { bundle } = useBasketContext();

  return (
    <>
      <Postcode
        addressType={addressType}
        clearBasket={clearBasket}
        onSubmit={(data) => {
          setPostcodeItem(data);
          setHasConfirmedAddress(false);
        }}
        onSelectRow={onSelectRow}
        registerInterestData={registerInterestData}
        showTitle={showTitle}
        inline={inline}
        placeholder={placeholder}
        bundle={bundle}
      />
      {featureList && <StyledPostcodeFeatureList list={featureList.list} icon={CheckIcon} />}
    </>
  );
};
