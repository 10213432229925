import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { ButtonProps, ButtonType } from '../types';
import chevron_down from '@public/icons/chevron_down_gradient_circle.png';

export const StyledButton = styled('button')<ButtonProps>`
  transition: all 0.2s;
  font-size: var(--fonts-sizing-button);

  ${({ size }) => `
    font-size: ${size === 'sm' ? rem(14) : ''};
  `}

  position: relative;
  font-family: var(--fonts-family-secondary);
  font-weight: 500;
  width: fit-content;
  cursor: pointer;
  line-height: 48px;
  display: ${(props) => (props.inline ? 'inline-flex' : 'flex')};
  align-items: center;
  justify-content: space-around;
  border-radius: 6px;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-around;
    // Make up for font misalignment
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0 1rem;
    border-radius: 6px;
    ${({ size }) => `
    padding: ${size === 'sm' ? '0.5rem .75rem' : ''};
    line-height: ${size === 'sm' ? '1' : ''};
  `}
  }

  ${(props) =>
    props.icon &&
    `
    & > div {
      padding-left: 3.5rem;
      background-image: url(${props.icon.src});
      background-repeat: no-repeat;
      background-position: .75rem calc(50% - .1rem);
      background-size: 1.75rem;
    }
  `}

  img {
    margin: 0 0.5rem;
  }

  &.pill {
    border-radius: 16px;
    padding: ${rem(2)} ${rem(10)};
    font-size: ${rem(14)};
    font-weight: 500;
    min-width: 4rem;
    margin-right: 0.5rem;
    color: var(--colors-grey--50);
    outline-color: rgba(185, 199, 247, 1);
  }

  ${({ buttonType }: ButtonProps) => {
    switch (buttonType) {
      case ButtonType.PRIMARY:
        return css`
          border: none;
          transform-style: preserve-3d;

          color: var(--colors-white);
          > div {
            transition: all 0.2s ease;
            background-color: var(--colors-primary-bright);
          }
          :hover,
          &.hovering {
            transform: scale(1.1);
            transition: all 0.2s ease;
          }
          &:before {
            content: '';
            position: absolute;
            inset: 10px;
            filter: blur(14px);
            transform: translate3d(0px, 12px, -1px);
            border-radius: inherit;
            pointer-events: none;
            background: transparent linear-gradient(99deg, #f4111b 0%, #0402fd 100%) 0% 0% no-repeat
              padding-box;
            width: 95%;
          }
          &:hover:before {
            background: transparent linear-gradient(99deg, #0402fd 0%, #f4111b 100%) 0% 0% no-repeat;
            transition: all 0.2s;
          }
        `;

      case ButtonType.SECONDARY:
        return css`
          border: none;
          transform-style: preserve-3d;
          background-color: var(--color-lightest-blue);
          color: var(--colors-primary);
          > div {
            background-color: #f7f7ff;
            transition: all 0.2s ease;
          }
          :hover,
          &.hovering {
            transform: scale(1.1);
            transition: all 0.2s ease;
            background-color: var(--colors-white);
          }
          &:before {
            content: '';
            position: absolute;
            inset: 10px;
            filter: blur(14px);
            transform: translate3d(0px, 12px, -1px);
            border-radius: inherit;
            pointer-events: none;
            background: transparent linear-gradient(99deg, #f4111b 0%, #0402fd 100%) 0% 0% no-repeat
              padding-box;
            width: 95%;
          }
          &:hover:before {
            background: transparent linear-gradient(99deg, #0402fd 0%, #f4111b 100%) 0% 0% no-repeat;
            transition: all 0.2s;
          }
        `;
      case ButtonType.SECONDARY_ROUNDED:
        return css`
          border: none;
          transform-style: preserve-3d;
          background-color: var(--color-lightest-blue);
          color: var(--colors-primary);
          border-radius: 6rem;
          font-weight: 300;
          > div {
            background-color: #f7f7ff;
            transition: all 0.2s ease;
            border-radius: 6rem;
            padding: 0 4rem;
          }
          :hover,
          &.hovering {
            transform: scale(1.1);
            transition: all 0.2s ease;
            background-color: var(--colors-white);
          }
          &:before {
            content: '';
            position: absolute;
            inset: 10px;
            filter: blur(14px);
            transform: translate3d(0px, 12px, -1px);
            border-radius: inherit;
            pointer-events: none;
            background: transparent linear-gradient(99deg, #f4111b 0%, #0402fd 100%) 0% 0% no-repeat
              padding-box;
            width: 95%;
          }
          &:hover:before {
            background: transparent linear-gradient(99deg, #0402fd 0%, #f4111b 100%) 0% 0% no-repeat;
            transition: all 0.2s;
          }
        `;

      case ButtonType.ICON:
        return css`
          background-color: var(--colors-white);
          color: var(--colors-primary);
          :hover,
          &.hovering {
            background-color: var(--colors-primary-30);
          }
        `;

      case ButtonType.HYPERLINK:
        return css`
          background-color: transparent;
          color: var(--colors-primary);
          font-weight: normal;
          text-decoration: none;
          padding: 0;
          :hover,
          &.hovering {
            transform: scale(1.1);
            transition: all 0.2s ease;
          }
        `;

      case ButtonType.OUTLINE:
        return css`
          border: 1px solid var(--colors-primary);
          background: transparent;
          color: var(--colors-primary);
          &:hover,
          &.hovering {
            background: var(--colors-primary);
            color: white;
          }
        `;
      case ButtonType.ALTERNATE:
        return css`
          border: none;
          transform-style: preserve-3d;
          background-color: #f4111b;
          color: var(--colors-white);
          > div {
            transition: all 0.2s ease;
          }
          :hover,
          &.hovering {
            transform: scale(1.1);
            transition: all 0.2s ease;
            background-color: var(--colors-primary);
          }
          &:before {
            content: '';
            position: absolute;
            inset: 10px;
            filter: blur(14px);
            transform: translate3d(0px, 12px, -1px);
            border-radius: inherit;
            pointer-events: none;
            background: transparent linear-gradient(99deg, #f4111b 0%, #0402fd 100%) 0% 0% no-repeat
              padding-box;
            width: 95%;
          }
          &:hover:before {
            background: transparent linear-gradient(99deg, #0402fd 0%, #f4111b 100%) 0% 0% no-repeat;
            transition: all 0.2s;
          }
        `;
      case ButtonType.SUMMARY:
        return css`
          display: flex;
          align-items: center;
          display: inlie-block;
          border: 2px solid var(--color-lightest-blue);
          background: transparent;
          color: var(--color-black);

          img {
            width: 20px;
            height: 20px;
            margin-bottom: 2px;
          }
        `;
      case ButtonType.TERTIARY:
        return css`
          color: var(--colors-black);
          text-decoration: underline;
          font-weight: medium;
          font-size: var(--fonts-sizing-primary);
        `;
      case ButtonType.HYPERLINK_SECONDARY:
        return css`
          color: var(--colors-primary);
          font-weight: medium;
          position: relative;
          line-height: 1.1;

          &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: var(--colors-primary);
            transition: width 0.3s;
          }

          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            right: -25px;
            width: 20px;
            height: 20px;
            background-image: url(${chevron_down.src});
            background-size: contain;
            background-repeat: no-repeat;
          }

          div {
            padding: 0;
          }
        `;
    }
  }}

  /* We also allow manual setting of color and border properties */

  ${(props: ButtonProps) =>
    props.bgColor &&
    css`
      background-color: ${props.bgColor};
    `}

  ${(props: ButtonProps) =>
    props.fgColor &&
    css`
      color: ${props.fgColor};
    `}

  ${(props: ButtonProps) =>
    props.border &&
    css`
      border: ${props.border};
    `}
  ${(props: ButtonProps) =>
    props.fullWidth &&
    css`
      width: 100%;
    `}

  /* Disabled overrides any other styles */

  &.disabled,
  &:disabled:before {
    background: white;
  }

  &.disabled,
  &:disabled {
    & > div {
      background-color: var(--colors-grey-30);
      color: var(--colors-white);
      :hover,
      &.hovering {
        filter: none;
        text-decoration: none;
        transform: none;
        transition: none;
        > div {
          transform: none;
        }
      }
    }
  }
`;
