import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { fromMarkdown, renderImage } from '@lib/utils';
import { breakpoints } from '@theme/breakpoints';
import { rem } from 'polished';
import { Format, ImageDataT, UprnStatus } from '@lib/types';
import { Postcode } from '@components/Organisms/Postcode/Postcode';
import { useRouter } from 'next/router';
import usePostcodeContext from '@hooks/usePostcodeContext';
import { postcodeSubmitCallback } from '@lib/utils/uprn';
import Link from 'next/link';
import { Button, Icon, TrustBox } from '@components/Atoms';
import { ButtonType } from '@components/Atoms/Button/types';
import { mockTrustBoxData } from '@components/Atoms/TrustBox/__mockData__';
import PhoneIcon from '@public/icons/Phone.svg';
import { formatPhoneNumber } from '@lib/utils/formatPhone';

interface BundleHeroT {
  display: boolean;
  pageTitle: string;
  pageSubTitle: string;
  body: string;
  subBody: string;
  image?: ImageDataT;
  bundleVariant2024Sept: string;
  phoneNumber: string;
  phoneText: string;
}

const STYLED = {
  styledBundleHero: styled.section`
    position: relative;
    overflow: hidden;

    @media ${breakpoints.smallDesktop} {
      min-height: 760px;
    }
  `,
  bundleHeroContent: styled.div`
    margin-right: auto;
    margin-top: ${rem(50)};

    @media ${breakpoints.tablet} {
      margin-top: 0;
    }
  `,
  bundleHeroTitleContent: styled.div`
    max-width: 60vw;

    @media ${breakpoints.smallDesktop} {
      max-width: 50vw;
    }

    @media ${breakpoints.desktopLarge} {
      max-width: 70vw;
    }
  `,
  bundleHeading: styled.h1`
    font-size: ${rem(50)};
    font-weight: 700;
    margin-bottom: 0;

    background: -webkit-linear-gradient(
      135deg,
      hsla(357, 91%, 51%, 1) 0%,
      hsla(240, 100%, 50%, 1) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media ${breakpoints.tablet} {
      font-size: ${rem(92)};
    }
  `,
  bundleHeroSubTitle: styled.div`
    & p {
      color: var(--color-primary);
      font-family: var(--font-geomanist);
      font-size: ${rem(25)};
      line-height: 1.1;
      font-weight: bold;

      @media ${breakpoints.tablet} {
        font-size: ${rem(35)};
      }
    }
  `,
  bundleBody: styled.div`
    margin-top: ${rem(100)};
    & * {
      font-size: ${rem(22)} !important;
      color: var(--color-grey);
    }

    @media ${breakpoints.smallDesktop} {
      margin-top: 0;
      max-width: 60%;
    }
  `,
  bundleSubBody: styled.div`
    & * {
      font-size: ${rem(22)} !important;
      font-weight: bold;
    }

    @media ${breakpoints.smallDesktop} {
      width: 60%;
    }
  `,
  heroImage: styled.div`
    position: absolute;
    z-index: -1;
    top: ${rem(10)};
    right: -130px;

    @media ${breakpoints.tablet} {
      right: 0;

      img {
        object-position: top;
      }
    }

    @media ${breakpoints.smallDesktop} {
      height: 100%;
      max-height: 760px;

      & > div {
        height: 100% !important;
      }

      & span {
        width: 45vw !important;
        height: 100% !important;

        img {
          object-fit: contain;
        }
      }
    }

    @media ${breakpoints.desktop} {
      img {
        object-position: top left;
      }
    }
  `,
  postcode: styled.div`
    margin-top: 1rem;

    [class*='StyledPostcodeContainer'] {
      margin-top: 1rem;
    }

    h3 {
      font-size: ${rem(20)};
      color: var(--color-black);
      margin-top: 2rem;
      font-weight: 600;
      margin-bottom: -1.5rem;
    }
  `,
  trustpilot: styled.div`
    height: 2rem;
    width: 300px;
    margin-left: -1.75rem;

    iframe {
      height: 2rem;
    }
  `,
  eligibleHeading: styled.h3`
    font-size: ${rem(22)};
    color: var(--color-primary);
    margin-top: 2rem;
    font-weight: 500;
    max-width: 25rem;
    line-height: 1.1;

    @media ${breakpoints.tablet} {
      max-width: 24rem;
      font-size: ${rem(25)};
    }
  `,
  eligibleBody: styled.p`
    font-size: ${rem(16)};
    color: black;
    margin-bottom: 1rem;
    font-weight: 300;
  `,
  eligibleButton: styled.div`
    margin-top: 2rem;
    margin-bottom: 3rem;
  `,
  primaryPhone: styled.a`
    display: flex;
    align-items: center;
    margin-top: 1rem;
    color: var(--color-primary);
    font-size: ${rem(22)};
    font-weight: 400;

    span {
      margin: 0 0.5rem;
    }
  `,
};

export const BundleHero: FC<BundleHeroT> = ({
  display,
  pageTitle,
  pageSubTitle,
  body,
  subBody,
  image,
  bundleVariant2024Sept,
  phoneNumber,
  phoneText,
}) => {
  const router = useRouter();
  const { isCurrentPostcodeEligible, setHasConfirmedAddress, setPostcodeItem, clearPostcode } =
    usePostcodeContext();

  const handlePostcodeSubmit = useCallback(
    (data) => {
      if (data?.coverage === UprnStatus.CUSTOMER) {
        clearPostcode();
        return router.push('/error/existing-customer');
      }
      postcodeSubmitCallback({
        data,
        ineligibleCallback: () => {
          return router.push('/service-unavailable');
        },
        successCallback: () => {
          setPostcodeItem(data);
          setHasConfirmedAddress(true);
        },
      });
    },
    [router, setHasConfirmedAddress, setPostcodeItem, clearPostcode],
  );

  const formattedPhoneNumber = formatPhoneNumber(phoneNumber);

  if (!display) return null;

  return (
    <STYLED.styledBundleHero className="slice">
      <div className="flex-col slice__inner">
        <STYLED.bundleHeroContent>
          <STYLED.bundleHeroTitleContent>
            <STYLED.bundleHeading
              dangerouslySetInnerHTML={{
                __html: pageTitle,
              }}
            />
            <STYLED.bundleHeroSubTitle>
              {fromMarkdown({ text: pageSubTitle, isHeading: false })}
            </STYLED.bundleHeroSubTitle>
          </STYLED.bundleHeroTitleContent>
          <STYLED.bundleBody>
            <div>{fromMarkdown({ text: body, isHeading: false })}</div>
          </STYLED.bundleBody>
          {bundleVariant2024Sept === 'b' && (
            <STYLED.bundleSubBody>
              <div>{fromMarkdown({ text: subBody, isHeading: false })}</div>
            </STYLED.bundleSubBody>
          )}
          <STYLED.postcode>
            {!isCurrentPostcodeEligible && (
              <>
                <h3>Can you get H!B?</h3>
                <Postcode
                  showTitle={false}
                  inline={true}
                  onSubmit={handlePostcodeSubmit}
                  placeholder=""
                  shouldRedirect={false}
                />
              </>
            )}
          </STYLED.postcode>
          {isCurrentPostcodeEligible && (
            <>
              <STYLED.eligibleHeading>
                Congrats! HeyBroadband is in your area
              </STYLED.eligibleHeading>
              <STYLED.eligibleBody>Learn more about our bundle</STYLED.eligibleBody>
              <STYLED.eligibleBody>
                <Link href="#whats-included" passHref>
                  <Button buttonType={ButtonType.PRIMARY}>
                    <a href="#whats-included">What&apos;s in the bundle?</a>
                  </Button>
                </Link>
              </STYLED.eligibleBody>
            </>
          )}
          <STYLED.trustpilot>
            <TrustBox data={mockTrustBoxData} />
          </STYLED.trustpilot>
          {phoneNumber && phoneText && (
            <Link href={`tel:${phoneNumber}`} passHref>
              <STYLED.primaryPhone className="primary-phone">
                <span>{phoneText}</span>
                <Icon xxs icon={PhoneIcon} />
                <span>{formattedPhoneNumber}</span>
              </STYLED.primaryPhone>
            </Link>
          )}
        </STYLED.bundleHeroContent>
        {image && (
          <STYLED.heroImage>
            {renderImage({
              className: 'lg:h-full',
              alternativeText: image.alternativeText,
              formats: image.formats,
              format: Format.LARGE,
            })}
          </STYLED.heroImage>
        )}
      </div>
    </STYLED.styledBundleHero>
  );
};
