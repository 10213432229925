import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import Media from 'react-media';

import useBasketContext from '@hooks/useBasketContext';
import usePostcodeContext from '@hooks/usePostcodeContext';
import { getAddressTypeFromSla } from '@lib/utils';

import { Button, Icon } from '@components/Atoms';
import { ButtonRow } from '@components/Molecules/ButtonRow/ButtonRow';
import { ButtonType } from '@components/Atoms/Button/types';

import { MiniBasketContents } from './MiniBasketContents';

import { breakpoints } from '@theme/breakpoints';
import StyledMiniBasket from './styled/StyledMiniBasket';
import StyledMiniBasketPopup from './styled/StyledMiniBasketPopup';
import StyledMiniBasketStatic from './styled/StyledMiniBasketStatic';
import StyledMiniBasketBackdrop from './styled/StyledMiniBasketBackdrop';
import StyledMiniBasketCloseBar from './styled/StyledMiniBasketCloseBar';

import ArrowUpIcon from '@public/icons/Arrow-Up.svg';
import ArrowDownIcon from '@public/icons/Arrow-Down.svg';
import BasketIcon from '@public/icons/Basket-Blue.svg';
import { useRouter } from 'next/router';
import { WithBasketContents } from '@hoc/WithBasketContents';
import { handleConditionalExecution } from '@lib/utils/conditionalExecution';

function getEditUrlFromSLA({ sla, isCurrentPostcodeAvailable }) {
  // TODO: send pre-orders to ROI page
  const url = isCurrentPostcodeAvailable ? 'service-available' : 'service-unavailable';
  const addressType = getAddressTypeFromSla(sla);
  return `/${url}?addressType=${addressType}#product-selector`;
}

export const MiniBasket = ({ miniBasketItem }: any) => {
  const MiniBasketContentsComponent = WithBasketContents({
    Component: MiniBasketContents,
    props: { basketItem: miniBasketItem },
  });

  return (
    <StyledMiniBasket className="basket-contents">
      {miniBasketItem ? <MiniBasketContentsComponent /> : <div>Nothing in basket</div>}
    </StyledMiniBasket>
  );
};

export const MiniBasketPopup = () => {
  const {
    basketItem,
    getBasketItemProduct,
    getAddonsProducts,
    promoCodes,
    isBasketPopupOpen,
    setIsBasketPopupOpen,
    bundle,
  } = useBasketContext();
  const miniBasketItem = getBasketItemProduct();
  const addonsProducts = getAddonsProducts();
  const { postcodeItem } = usePostcodeContext();

  useEffect(() => {
    document?.querySelector('html')?.classList[isBasketPopupOpen ? 'add' : 'remove']('no-scroll');
  }, [isBasketPopupOpen]);

  const router = useRouter();
  const { pathname } = router || {};

  const isCheckingOut =
    ['/service-available', '/service-property-confirmation', '/extras'].indexOf(pathname) > -1;

  // Disable OTS check
  const trueCallback = () => {
    if (!postcodeItem) {
      return '/address-finder';
    }
    return '/service-property-confirmation';
  };

  const falseCallback = () => {
    if (!postcodeItem) {
      return '/address-finder';
    }
    return '/ots';
  };

  const redirect = handleConditionalExecution(
    process.env.NEXT_PUBLIC_DISABLE_OTS === 'true',
    trueCallback,
    falseCallback,
  );

  return (
    <>
      {isBasketPopupOpen && (
        <div>
          <StyledMiniBasketBackdrop onClick={() => setIsBasketPopupOpen(false)} />
          <StyledMiniBasketPopup>
            <StyledMiniBasketCloseBar onClick={() => setIsBasketPopupOpen(false)}>
              <div>
                <Icon xs icon={BasketIcon} />
              </div>
              <div>Your Basket</div>
              <div>
                <Icon xs icon={ArrowUpIcon} />
              </div>
            </StyledMiniBasketCloseBar>
            <MiniBasket
              miniBasketItem={miniBasketItem}
              addonsProducts={addonsProducts}
              promoCodes={promoCodes}
            />
            {basketItem && !isCheckingOut && (
              <div className="mt-6">
                <ButtonRow column>
                  <div>
                    <Link passHref href={bundle && postcodeItem ? '/checkout' : redirect}>
                      <Button buttonType={ButtonType.PRIMARY} fullWidth as="a" href="/checkout">
                        Continue to Checkout
                      </Button>
                    </Link>
                  </div>
                </ButtonRow>
              </div>
            )}
          </StyledMiniBasketPopup>
        </div>
      )}
    </>
  );
};

export const MiniBasketStatic = ({ readOnly = false, show = false }) => {
  const { getBasketItemProduct, getAddonsProducts, promoCodes, clearBasket } = useBasketContext();
  const miniBasketItem = getBasketItemProduct();
  const addonsProducts = getAddonsProducts();
  const router = useRouter();

  return (
    <>
      <Media queries={breakpoints}>
        {({ tablet }) => (
          <StyledMiniBasketStatic className="mini-basket" show={show}>
            <div className="flex justify-between gap-4">
              <h3 className="font-geomanist text-primary md:text-[2rem]">Your selection</h3>
              <button
                onClick={async () => {
                  clearBasket();
                  return await router.push('/service-available');
                }}
                className="hidden md:block text-primary text-[.75rem] font-bold"
              >
                <span className="underline">Reset selection</span>
              </button>
            </div>
            <MiniBasket
              miniBasketItem={miniBasketItem}
              addonsProducts={addonsProducts}
              promoCodes={promoCodes}
            />
          </StyledMiniBasketStatic>
        )}
      </Media>
    </>
  );
};
